import { SessionExpiredDialog } from "@rio-cloud/rio-session-expired-info";
import ApplicationLayout from "@rio-cloud/rio-uikit/ApplicationLayout";
import ApplicationLayoutBodyBanner from "@rio-cloud/rio-uikit/components/applicationLayout/ApplicationLayoutBodyBanner";
import NotificationsContainer from "@rio-cloud/rio-uikit/NotificationsContainer";
import { isEmpty } from "lodash";
import { useEffect, useRef } from "react";
import { FormattedMessage, IntlProvider } from "react-intl";
import { Outlet } from "react-router-dom";
import WelcomePlaceholder from "../components/welcome/WelcomePlaceholder";
import { DEFAULT_LOCALE } from "../configuration/lang/lang";
import { getDisplayMessages, getLocale } from "../configuration/lang/langSlice";
import { isUserSessionExpired } from "../configuration/login/loginSlice";
import { useAppDispatch, useAppSelector } from "../configuration/setup/hooks";
import useCurrentPath from "../hooks/useCurrentPath";
import useQueryParams from "../hooks/useQueryParams";
import DefaultRedirect from "../routes/DefaultRedirect";
import RouteUpdater from "../routes/RouteUpdater";
import { getAppData, getSessionExpiredAcknowledged, hideSessionExpiredDialog, setScreenType } from "../stores/app/appSlice";
import "./App.css";
import AppHeader from "./header/AppHeader";
import ApplicationSidebar from "./sidebar/ApplicationSidebar";

const AppLayout = () => {
	const dispatch = useAppDispatch();
	const sidebarRef = useRef<HTMLDivElement>(null);
	const { assets, tags } = useAppSelector(getAppData);
	const userLocale = useAppSelector(getLocale);
	const displayMessages = useAppSelector(getDisplayMessages);
	const isSessionExpired = useAppSelector(isUserSessionExpired);
	const sessionExpiredAcknowledged = useAppSelector(getSessionExpiredAcknowledged);
	const path = useCurrentPath();
	const routes = ["notifications", "recurrence"];
	const noWelcome = path && routes?.includes(path);
	const { getQueryParam } = useQueryParams();
	const { dateTime } = useAppSelector(getAppData);

	const initialMount = useRef(true);

	const throttledResizeHandler = () => {
		const width = window.innerWidth;
		let screenType = "DESKTOP_SCREEN";

		if (width < 768) {
			screenType = "MOBILE_SCREEN";
		} else if (width >= 768 && width < 1200) {
			screenType = "TABLET_SCREEN";
		}

		dispatch(
			setScreenType({
				MOBILE_SCREEN: screenType === "MOBILE_SCREEN",
				TABLET_SCREEN: screenType === "TABLET_SCREEN",
				DESKTOP_SCREEN: screenType === "DESKTOP_SCREEN",
			}),
		);
	};

	useEffect(() => {
		window.addEventListener("resize", throttledResizeHandler);

		return () => {
			window.removeEventListener("resize", throttledResizeHandler);
		};
	}, []);

	useEffect(() => {
		throttledResizeHandler();
	}, []);

	if (!(displayMessages && userLocale)) {
		return null;
	}

	const handleSessionExpiredDialogClose = () => dispatch(hideSessionExpiredDialog());
	const showSessionExpired = isSessionExpired && !sessionExpiredAcknowledged;

	return (
		<IntlProvider defaultLocale={DEFAULT_LOCALE} key={userLocale} locale={userLocale} messages={displayMessages}>
			<RouteUpdater />
			<ApplicationLayout data-testid={"app-layout"}>
				<ApplicationLayout.Header>
					<AppHeader />
				</ApplicationLayout.Header>
				<ApplicationLayout.Sidebar className="left" ref={sidebarRef}>
					{<ApplicationSidebar />}
				</ApplicationLayout.Sidebar>
				<ApplicationLayout.Sidebar className="right" ref={sidebarRef} />
				<ApplicationLayout.Body>
					<NotificationsContainer />
					<SessionExpiredDialog locale={userLocale} onClose={handleSessionExpiredDialogClose} show={showSessionExpired} />
					{noWelcome ? (
						<Outlet />
					) : !isEmpty(assets) || !isEmpty(tags) ? (
						<>
							<Outlet />
						</>
					) : (
						<>
							<WelcomePlaceholder />
						</>
					)}
					<DefaultRedirect />
				</ApplicationLayout.Body>
			</ApplicationLayout>
		</IntlProvider>
	);
};

export default AppLayout;
