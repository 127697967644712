import Checkbox from "@rio-cloud/rio-uikit/components/checkbox/Checkbox";
import { memo } from "react";

interface AssetRow {
	tagName: string;
	isTagSelected: boolean;
	asset: {
		id: string;
		name: string;
	};
	onAssetSelect: (assetId: string) => void;
	isAssetSelected: (assetId: string) => boolean;
}

const AssetRow = memo(({ asset, isTagSelected, isAssetSelected, tagName, onAssetSelect }: AssetRow) => {
	return (
		<div className={`TreeLeaf form-group margin-bottom-0 ${tagName == "search-results-sidebar" ? "search" : ""}`} data-key={asset.id}>
			{!isTagSelected && (
				<Checkbox className="TreeCheckbox" checked={isAssetSelected(asset.id)} onChange={(e: any) => onAssetSelect(asset.id)} />
			)}
			<span className="TreeLabel">
				<span className="TreeLabelName">
					<span className="rioglyph rioglyph-truck"></span>
					<span className="TreeLabelNameText">
						<span className="TreeLabelNameTextHeadline">{asset.name}</span>
					</span>
				</span>
			</span>
		</div>
	);
});

export default AssetRow;
