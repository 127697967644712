import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../configuration/setup/hooks";
import { paramsDate, paramsTruckIds, paramsTruckTags } from "../constants/params";
import useQueryParams from "../hooks/useQueryParams";
import {
	addAssetsSelected,
	addrangeIdAssets,
	addrangeIdTagss,
	addTagsSelected,
	getAppData,
	setOverallAssetsSelected,
} from "../stores/app/appSlice";
import { getRangesFromIds, rangesToString, stringToRanges } from "../utils/url-state";
import { parseRoute } from "./routeHelper";

const RouteUpdater = () => {
	const {
		assets: assetsGlobal,
		tags: tagsGlobal,
		allAssetsWithoutTag,
		allTags,
		allAssets,
		dateTime,
		rangeIdAssets,
		rangeIdTags,
	} = useAppSelector(getAppData);
	const { setQueryParam, deleteQueryParam, getQueryParam } = useQueryParams();
	const dispatch = useAppDispatch();
	const { search } = useLocation();

	// convert UUID from global state to range
	useEffect(() => {
		const findIdsFromTag: number[] = [];
		const findIdsFromIds: number[] = [];

		allTags.forEach((tag, index) => {
			if (tagsGlobal.some((globalTag) => globalTag === tag.id)) {
				findIdsFromTag.push(index);
			}
		});

		allAssets?.forEach((truck, index) => {
			if (assetsGlobal.some((globalTag) => globalTag === truck.id)) {
				findIdsFromIds.push(index);
			}
		});

		dispatch(addrangeIdAssets(findIdsFromIds));
		dispatch(addrangeIdTagss(findIdsFromTag));

		if (allTags.length > 0 && tagsGlobal.length == 0) deleteQueryParam(paramsTruckTags);
		if (allAssets.length > 0 && assetsGlobal.length == 0) deleteQueryParam(paramsTruckIds);
	}, [tagsGlobal, assetsGlobal]);

	// global state management
	useEffect(() => {
		const routeSearchParams = parseRoute(search);
		const { truckTags, truckIds } = routeSearchParams;
		if (!allAssets) return;

		let overallAssetsSelected = new Set();

		if (truckTags) {
			const decodedTruckTags = stringToRanges(getQueryParam(paramsTruckTags)!);
			const validTruckTags = allTags || [];
			const trucksTagUUID = decodedTruckTags.map((index: number) => validTruckTags[index]?.id).filter((id) => id !== undefined);
			dispatch(addTagsSelected(trucksTagUUID));

			if (trucksTagUUID.includes("empty")) {
				dispatch(addAssetsSelected(allAssets.map((item) => item.id)));
			}

			const filterAssetsByTags = (assets: any[], tags: string | any[]) => {
				return assets.filter((asset: { groupIds: any[] }) => asset.groupIds.some((groupId: any) => tags.includes(groupId)));
			};

			const filteredAssets = filterAssetsByTags(allAssets, trucksTagUUID);
			filteredAssets.forEach((asset) => overallAssetsSelected.add(asset.id));
		}

		if (truckIds) {
			const validTruckIds = allAssets || [];
			const decodedTruckIds = stringToRanges(getQueryParam(paramsTruckIds)!);
			const trucksIdsUUID = decodedTruckIds.map((index: number) => validTruckIds[index]?.id).filter((id: string) => id !== undefined);
			dispatch(addAssetsSelected(trucksIdsUUID));

			trucksIdsUUID.forEach((id) => overallAssetsSelected.add(id)); // Add truck IDs to Set
		}

		dispatch(setOverallAssetsSelected(Array.from(overallAssetsSelected)));
	}, [allAssets]);

	// url management
	useEffect(() => {
		if (
			assetsGlobal.length > 0 &&
			rangeIdAssets.length > 0 &&
			tagsGlobal.length !== allTags.length &&
			assetsGlobal.length !== allAssets.length &&
			assetsGlobal !== allAssetsWithoutTag
		) {
			setQueryParam(paramsTruckIds, rangesToString(getRangesFromIds(rangeIdAssets)));
		}

		if (tagsGlobal.length == 0 && assetsGlobal.length > 0 && rangeIdAssets.length > 0) {
			setQueryParam(paramsTruckIds, rangesToString(getRangesFromIds(rangeIdAssets)));
		}

		if (tagsGlobal.length > 0 && rangeIdTags.length > 0) {
			setQueryParam(paramsTruckTags, rangesToString(getRangesFromIds(rangeIdTags)));
		}
	}, [rangeIdTags, rangeIdAssets]);

	useEffect(() => {
		setQueryParam(paramsDate, JSON.stringify(dateTime));
	}, [dateTime]);

	return null;
};

export default RouteUpdater;
